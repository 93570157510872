import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Nav.css';

const Nav = props => {
    return (
        <nav>
            <ul>
                <li>
                    <NavLink to="/kanto" activeClassName='active'>Kanto</NavLink>
                </li>
                <li>
                    <NavLink to="/johto" activeClassName='active'>Johto</NavLink>
                </li>
                <li>
                    <NavLink to="/hoenn" activeClassName='active'>Hoenn</NavLink>
                </li>
                <li>
                    <NavLink to="/sinnoh" activeClassName='active'>Sinnoh</NavLink>
                </li>
                <li>
                    <NavLink to="/unova" activeClassName='active'>Unova</NavLink>
                </li>
                <li>
                    <NavLink to="/kalos" activeClassName='active'>Kalos</NavLink>
                </li>
            </ul>
        </nav >
    );
}

export default Nav;