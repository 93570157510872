import React, { Component } from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';
import '../styles/PokemonCard.css';

import PokemonCards from './PokemonCard'

class Region extends Component {
    state = {
        pokemons: [],
        types: [],
        filteredType: "",
        region: ""
    }

    fetchPokemons = name => {
        let pokemonID, limit;

        if (name === 'kanto') {
            pokemonID = 0;
            limit = 151;
        } else if (name === 'johto') {
            pokemonID = 151;
            limit = 100;
        } else if (name === 'hoenn') {
            pokemonID = 251;
            limit = 135;
        } else if (name === 'sinnoh') {
            pokemonID = 386;
            limit = 107;
        } else if (name === 'unova') {
            pokemonID = 494;
            limit = 155;
        } else if (name === 'kalos') {
            pokemonID = 649;
            limit = 72;
        }
        if (this.state.region !== name) {
            this.setState(prevState => {
                if (prevState.region !== name) {
                    return ({
                        region: name
                    });
                }
            });
        }

        if (this.state.region !== name)
            fetch(`https://pokeapi.co/api/v2/pokemon?offset=${pokemonID}&limit=${limit}`)
                .then((result) => {
                    return result.json();
                }).then(data => {
                    this.setState(prevState => {
                        if (prevState.pokemons !== data.result)
                            return ({
                                pokemons: data.results
                            })
                    })
                }).then(() => {
                    this.fetchPokemonsTypes();
                })
                .catch((err) => {
                    console.log(err);
                });
    }

    fetchPokemonsTypes = () => {
        if (!_.isEmpty(this.state.pokemons)) {
            _.map(this.state.pokemons, (pokemon) => {
                fetch(`https://pokeapi.co/api/v2/pokemon/${pokemon.name}`).then((result) => {
                    return result.json();
                }).then(pokemonStat => {
                    if (pokemonStat.types) {
                        _.forEach(pokemonStat.types, type => {
                            this.setState({
                                types: _.uniq([...this.state.types, type.type.name])
                            });
                        })
                    }
                }).catch((err) => {
                    console.log(err);
                });
            });
        }
    }

    componentWillMount = () => {
        this.fetchPokemons(this.props.name);
    }

    componentDidUpdate = () => {
        this.fetchPokemons(this.props.name);
    }
    pokemonLegend = () => {
        console.log(this.state.types);
        return _.map(_.uniq(this.state.types), (type, id) => {
            return <div className={classnames("legend-box", {'active': type === this.state.filteredType})} key={id} onClick={() => this.setState({filteredType: type})}>
                <span className={type}/>
                <p>{type}</p>
            </div>
        });
    }
    pokemonCards = () => {
        let pokemonCard = this.state.pokemons.map(pokemon => {
            return (
                <PokemonCards key={pokemon.name} pokemon={pokemon} filteredType={this.state.filteredType}/>
            );
        })
        return pokemonCard;
    }
    render() {
        return (
            <React.Fragment>
                <h2>Welcome to <span className="regionName">{this.props.name}</span> region.</h2>
                <div className="pokemonCards">
                    <div id="legend">
                        <div className={classnames("legend-box", {'active': this.state.filteredType === ''})} onClick={() => this.setState({filteredType: ''})}>
                            <p>show all</p>
                        </div>
                        {this.pokemonLegend()}
                    </div>
                    <div id="cards">
                        {this.pokemonCards()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Region;