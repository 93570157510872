import React from 'react';
import Nav from '../components/Nav'
import { Link } from 'react-router-dom';

const Header = props => {
    return (
        <header>
            <Link to="/"><h1 className="logo">Pokedex</h1></Link>
            <Nav click={props.click} />
        </header>
    );
}

export default Header;