import React from 'react';
import '../styles/Footer.css'

const Footer = () => {
    return (
        <footer>
            <h1>Mateusz Szczykutowicz &copy; 2020</h1>
        </footer>
    );
}

export default Footer;