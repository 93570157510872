import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Region from '../components/Region'
import Pokemon from '../components/Pokemon'
import Home from '../components/Home';

class Main extends Component {

    render() {
        return (
            <main>
                <Switch>
                    <Route path='/' exact render={() => <Home/>} />
                    <Route path="/kanto" render={() => <Region name="kanto" />} />
                    <Route path="/johto" render={() => <Region name="johto" />} />
                    <Route path="/hoenn" render={() => <Region name="hoenn" />} />
                    <Route path="/sinnoh" render={() => <Region name="sinnoh" />} />
                    <Route path="/unova" render={() => <Region name="unova" />} />
                    <Route path="/kalos" render={() => <Region name="kalos" />} />
                    <Route path="/pokemon/:name" component={Pokemon} />
                </Switch>
            </main>
        );
    }
}

export default Main;