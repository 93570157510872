import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Pokemon.css'

class Pokemon extends Component {
    state = {
        pokemonData: null
    }

    componentDidMount() {
        const pokemonName = this.props.match.params.name;
        fetch(`https://pokeapi.co/api/v2/pokemon/${pokemonName}/`)
            .then((result) => {
                return result.json()
            }).then(data => {
                this.setState({
                    pokemonData: data
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    loadData = () => {
        if (this.state.pokemonData) {
            let region = "";

            const pokeStats = { ...this.state.pokemonData }
            const types = pokeStats.types.map(type => <div key={type.slot}>{type.type.name}</div>).reverse();
            const stats = pokeStats.stats.map(
                stat => <div key={stat.stat.name}>{stat.stat.name}: {stat.base_stat}</div>
            ).reverse();
            const abilities = pokeStats.abilities.map(ability => <div key={ability.ability.name}>{ability.ability.name}</div>)
            if (pokeStats.id > 0 && pokeStats.id < 152) {
                region = 'kanto';
            } else if (pokeStats.id > 151 && pokeStats.id <= 251) {
                region = 'johto'
            } else if (pokeStats.id >= 252 && pokeStats.id <= 385) {
                region = 'hoenn'
            } else if (pokeStats.id >= 386 && pokeStats.id <= 493) {
                region = 'sinnoh'
            } else if (pokeStats.id >= 494 && pokeStats.id <= 649) {
                region = 'unova'
            } else if (pokeStats.id >= 650 && pokeStats.id <= 721) {
                region = 'kalos'
            }
            return (
                <React.Fragment>
                    <div className={`pokemon ${types[0].props.children}`}>
                        <header>
                            <h1>{this.state.pokemonData.id}. {this.state.pokemonData.name.toUpperCase()}</h1>
                        </header>
                        <div className="pokemonStats">
                            {stats}
                        </div>
                        <div className="pokemonType">
                            <h2>Type:</h2>
                            <div>
                                {types}
                            </div>
                        </div>
                        <img src={this.state.pokemonData.sprites.front_default} alt={`Pokemon ${this.pokemonName}`} />
                        <div className="pokemonProfile">
                            <h2>Profile:</h2>
                            <div>
                                Height: {`${pokeStats.height / 10}m`}
                            </div>
                            <div>
                                Weight: {`${pokeStats.weight / 10}kg`}
                            </div>
                            <div>
                                <h4>Abilities: </h4>
                                {abilities}
                            </div>
                        </div>
                        <Link to={`/${region}`}><button>Back</button></Link>
                    </div>
                </React.Fragment>

            )
        } else {
            return (
                <h1>Loading...</h1>
            );

        }
    }

    render() {
        return (
            <React.Fragment>
                {this.loadData()}
            </React.Fragment>
        );
    }
}

export default Pokemon;