import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../styles/PokemonCard.css';

import loading from '../img/loading.gif'

class PokemonCard extends Component {
    state = {
        pokemonStat: []
    }

    componentDidMount = () => {
        fetch(`https://pokeapi.co/api/v2/pokemon/${this.props.pokemon.name}`).then((result) => {
            return result.json();
        }).then(pokemonStat => {
            this.setState({
                pokemonStat
            })
        }).catch((err) => {
            console.log(err);
        });
    }

    loadPokemon = () => {
        const types = this.state.pokemonStat.length !== 0 && this.state.pokemonStat.types.reverse();
        if (types && this.props.filteredType === types[0].type.name || types && this.props.filteredType === '') {
            return <div className={`pokemonCard ${types[0].type.name}`}>
                <Link to={`/pokemon/${this.props.pokemon.name}`}>
                    <h3>{this.state.pokemonStat.id}. {this.props.pokemon.name}</h3>
                    <img src={this.state.pokemonStat.sprites.front_default} alt={`Pokemon ${this.props.pokemon.name}`} />
                </Link>
            </div>
        }

    }
    render = () => {
        return (
            <React.Fragment>
                {this.loadPokemon()}
            </React.Fragment>
        );
    }
}


export default PokemonCard;