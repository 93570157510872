import React, { Component } from 'react';
import './styles/App.css';

import Header from './layout/Header';
import Main from './layout/Main';
import Footer from './layout/Footer';
import { HashRouter } from 'react-router-dom';

class App extends Component {

  state = {

  }

  render() {
    return (
      <React.Fragment>
        <HashRouter>
          <div className="wrapper"></div>
          <Header click={this.fetchPokemons} />
          <Main />
          <Footer />

        </HashRouter>

      </React.Fragment>
    );
  }
}


export default App;
